<template>
  <div class="introduction-container">
    <!-- 顶部导航 -->
    <top-nav currTab="1" height="100%"> </top-nav>

    <div class="content-box">
      <div class="content-warpper">
        <div class="warpper-left">
          <div class="big-title">
            <p class="line light">APP</p>
            <p class="line light">INTRODUCTION</p>
            <p class="line dark">工解APP</p>
          </div>

          <div class="describe">
            该应用是一款关注实业新闻资讯的平台，主要为用户提供实体
            相关行业的信息资讯，让客户充分了解所在实体行业的市场发
            展状况和发展趋势，并对行业状况发表自己的观点。该应用有 以下特点：
          </div>

          <!-- 特点描述 -->
          <div class="feature-list">
            <div
              class="feature-item"
              v-for="(item, index) in featureList"
              :key="'feature' + index"
            >
              <div class="item-icon">
                <img :src="rowRightImage" alt="" />
              </div>
              <div class="item-text">
                {{ item.text }}
              </div>
            </div>

            <img :src="startImage2" class="bg-start-2" alt="" />
          </div>

          <!-- 下载区域 -->
          <div class="download-area">
            <div class="qrcode-content">
              <div class="qrcode-image">
                <img :src="qrcodeImage" alt="" />
              </div>
              <p class="text">手机扫码轻松下载</p>
            </div>

            <div class="download-content">
              <div class="download-item" @click="downloadApp">
                <span>Android</span>
                <div class="download-bar"></div>
                <img class="download-icon" :src="downloadImage" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="warpper-right">
          <div class="introduction-main-box">
            <img
              class="introduction-main-bar"
              :src="introductionMainBarImage"
              alt=""
            />
          </div>

          <!-- 星星 -->
          <img class="bg-start-1" :src="startImage1" alt="" />
        </div>
      </div>

      <!-- 波浪 -->
      <img class="introduction-wave" :src="introductionWaveImage" alt="" />
    </div>

    <bottom-nav></bottom-nav>
  </div>
</template>

<script setup>
import topNav from "@/components/topNav";
import bottomNav from "@/components/bottomNav";

/* 图片 */
// 页面背景
const introductionBgImage = require("@/assets/images/introduction-bg.png");
// 向左
const rowRightImage = require("@/assets/images/row-right.png");
// 二维码
const qrcodeImage = require("@/assets/images/download-qrcode.png");
// 下载标识
const downloadImage = require("@/assets/images/down.png");
// 星星图标1
const startImage1 = require("@/assets/images/bg-start-1.png");
// 星星图标
const startImage2 = require("@/assets/images/bg-start-2.png");
// 主要标识
const introductionMainBarImage = require("@/assets/images/introduction-main-bar.png");
// 波浪背景
const introductionWaveImage = require("@/assets/images/introduction-wave.png");

// 描述特点
const featureList = [
  {
    text: "广泛：提供广泛而丰富的资讯内容",
  },
  {
    text: "价值：提供有价值的实体行业市场资讯",
  },
  {
    text: "发现：从信息中发现行业市场和行业趋势",
  },
  {
    text: "直观：可通过视频直观了解实体企业生产过程。",
  },
];

// 下载APP
const downloadApp = () => {
  var a = document.createElement("a");
  a.href = "https://www.allsidescombine.com/apk/gongjie.apk";
  a.download = "工解";
  a.click();
  a.remove();
};
</script>

<style lang="scss">
.introduction-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;

  .content-box {
    background-image: url("../assets/images/introduction-bg.png");
    background-color: #e5edff;
    background-size: 2000px 1080px;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    background-position: -150px -200px;

    .content-warpper {
      width: 1200px;
      margin: auto;
      display: grid;
      grid-template-columns: 500px 1fr;
      height: 100%;

      .warpper-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .big-title {
          position: relative;
          .line {
            &.light {
              color: rgb(65, 136, 255, 0.1);
              font-size: 54px;
              font-weight: bold;
            }
            &.dark {
              color: #000000;
              font-size: 79px;
              position: absolute;
              top: 20px;
              font-weight: bold;
            }
          }
        }

        .describe {
          color: #6a6a6a;
          font-size: 16px;
          width: 435px;
          margin-bottom: 32px;
          line-height: 30px;
        }

        .feature-list {
          margin-bottom: 84px;
          position: relative;
          .feature-item {
            display: grid;
            grid-template-columns: auto 1fr;
            color: #6a6a6a;
            font-size: 16px;
            margin-bottom: 10px;
            align-items: center;

            .item-icon {
              width: 15px;
              height: 17px;
              margin-right: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .bg-start-2 {
            position: absolute;
            width: 81px;
            right: 0;
            top: 0;
          }
        }

        // 下载区域
        .download-area {
          display: grid;
          grid-template-columns: auto 1fr;
          width: 300px;
          position: relative;
          z-index: 2;
          .qrcode-content {
            .qrcode-image {
              width: 110px;
              height: 110px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              color: #6a6a6a;
              font-size: 14px;
              line-height: 30px;
            }
          }

          .download-content {
            display: flex;
            flex-direction: column;
            margin-left: 35px;
            .download-item {
              position: relative;
              border: 2px solid #000000;
              padding-left: 16px;
              overflow: hidden;
              padding: 7px;
              border-radius: 5px;
              font-weight: bold;
              cursor: pointer;
              padding-left: 18px;
              margin-top: 35px;
              .download-bar {
                position: absolute;
                background-color: #000000;
                right: -10px;
                width: 60px;
                height: 160%;
                top: -4px;
                transform: rotate(120deg);
                text-align: left;
              }
              .download-icon {
                position: absolute;
                width: 16px;
                height: 22px;
                right: 13px;
                top: 7px;
              }
            }
          }
        }
      }

      .warpper-right {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .introduction-main-box {
          height: 858px;
          .introduction-main-bar {
            width: 589px;
            height: 1544px;
          }
        }

        .bg-start-1 {
          width: 130px;
          height: 130px;
          position: absolute;
          top: 20px;
          right: 0;
        }
      }
    }

    // 波浪
    .introduction-wave {
      position: absolute;
      left: -15%;
      bottom: -25%;
      width: 120%;
      min-width: 1920px;
      background-attachment: fixed;
    }
  }

  .bottom-nav-box {
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
